.skills-header-div {
  margin-top: 60px;
}

.skills-header {
  font-size: var(--titleSize);
  line-height: 1.1;
  font-family: var(--font-google-sans-regular);
  text-align: center;
}

/* 
.skills-section {
  display: flex;
} */

/* .skills-section > * {
  flex: 1;
  margin-bottom: 30px;
} */

.skills-section-image-div > * {
  max-width: 100%;
  height: auto;
  margin-top: 110px;
}

.skills-section-text-div {
  margin-left: 50px;
  margin-top: 3%;
  padding-top: 80px;
  /* font-size: large; */
  font-family: var(--font-google-sans-regular);
}

.skills-section-heading {
  font-size: var(--subtitleSize);
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
  font-family: var(--font-google-sans-regular);
}

.skills-text {
  font-size: var(--textSize) !important;
  margin-top: 10px;
  text-align: center;
}

@media (max-width: 1380px) {
  .skills-header {
    font-size: var(--titleSizeMaxWidth1380);
  }

  .skills-section-heading {
    font-size: var(--subtitleSizeMaxWidth1380);
    font-weight: 300;
  }

  .skills-text {
    font-size: var(--textSizeMaxWidth1380) !important;
    margin-top: 10px;
    text-align: center;
  }
}
@media (max-width: 1110px) {
  .skills-header {
    font-size: var(--titleSizeMaxWidth1100);
  }

  .skills-section-heading {
    font-size: var(--subtitleSizeSizeMaxWidth1100);
  }

  .skills-text {
    font-size: var(--textSizeMaxWidth1100);
    text-align: center;
  }

  /* .skills-section {
    flex-direction: column;
  } */

  .skills-section-image-div {
    order: 2;
  }
}

@media (max-width: 960px) {
  .skills-section-image-grid {
    order: 2;
  }
}

@media (max-width: 760px) {
  .skills-header {
    font-size: var(--titleSizeMaxWidth760);
  }

  .skills-section-heading {
    font-size: var(--subtitleSizeSizeMaxWidth760);
  }

  .skills-text {
    font-size: var(--textSizeMaxWidth760) !important;
    margin-top: 10px;
    text-align: center;
  }
}
