.dev-icons {
    padding-left: 0 !important;
    font-size: 3rem;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 0px !important;
    /* text-align: center; */

  }
  
  .contact-platforms-inline {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  
  