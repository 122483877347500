

.greeting-text-grid-div{

  /* display: inline; */
  margin-left: 50px !important;
  /* align-items: left;
  justify-content: left; */
}


/* 
.greeting-text-grid {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.contact-platforms{
  margin-left: 0px;
}

.greeting-title {
  margin-top: 130px;
  /* margin-left: 50px; */
  font-size: var(--titleSize);
  line-height: 6px;
  font-family: var(--font-google-sans-bold);
}

.greeting-sub-title {
  font-size: var(--subtitleSize);
  line-height: 40px;
  /* margin-left: 50px; */
  margin-top: 30px;
  font-family: var(--font-google-sans-medium);
}

.greeting-p {
  /* margin-left: 50px; */
  font-size: var(--textSize);
}

.greeting-image-grid > * {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1380px) {
  .greeting-title {
    font-size: var(--titleSizeMaxWidth1380);
    line-height: 5px;
  }

  .greeting-sub-title {
    font-size: var(--subtitleSizeMaxWidth1380);
    line-height: 40px;
  }

  .greeting-p {
    font-size: var(--textSizeMaxWidth1380);
  }
}

@media (max-width: 1110px) {
  .greeting-title {
    font-size: var(--titleSizeMaxWidth1100);
    line-height: 5px;
  }

  .greeting-sub-title {
    font-size: var(--subtitleSizeSizeMaxWidth1100);
    line-height: 40px;
  }

  .greeting-p {
    font-size: var(--textSizeMaxWidth1100);
  }
}
