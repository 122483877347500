.dev-icons {
    padding-left: 0;
    font-size: 4rem;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
  }
  
  .programming-platforms-inline {
    display: inline-block;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .programmingPlatformIcons{
    height: 50px;
    width : 50px
  }

  @media (max-width: 450px) {
    .programmingPlatformIcons{
      height: 25px;
      width : 25px
    }
  
  }
  