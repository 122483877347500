.profile {
  border-radius: 50px;
  border-style: solid;
  border-width: 1px;
  width: 100%;
  display: inline-block;
  padding-top: 10px;
}

.profile_name {
  line-height: 18px;
  padding: 20px;
}

.sticky-top {
  position: sticky !important;
  /* top: 10px; */
}

.profile_name .name {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  line-height: 50px;
}

.profile_name .title {
  font-size: var(--textSize);
  line-height: 40px;
}

.profile_image {
  margin-top: -10px;
  -webkit-clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
  clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
}

.profile_image img {
  width: 100%;
}

.profile_information {
  top: 0;
  left: 0;
  margin-top: -45px;
  margin-left: 10px;
  min-height: 120px;
}

.profile_information .timeline .timeline-content {
  padding-top: 2px;
  margin-bottom: 12px;
  display: inline-flex !important;
}

.profile_information .timeline .timeline-content .MuiSvgIcon-root {
  font-size: 22px !important;
}

.profile .button-container {
  width: 100%;
  justify-content: center;
  display: flex !important;
  margin-top: 15px;
  margin-bottom: 15px;
}

.timeline-item-text {
  line-height: 40px !important;
  font-size: 16px !important;
  font-weight: 400;
}

.timeline-item-text > span {
  font-weight: 550;
}

.timeline-item-text > a {
  text-decoration: none;
  color: burlywood;
}
