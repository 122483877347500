.header {
  margin-bottom: 30px !important;
  padding: 0px 22px 0px 0px !important;
  border-radius: 50px !important;
  border-style: solid;
  border-width: 1px;
}

.header .header_link {
  font-weight: 500;
  font-size: 16px !important;
  margin-right: 12px !important;
  line-height: 40px;
}

.header .nav-link {
  padding: 0px !important;
  text-align: center;
}

.header .header_link_active {
  font-weight: 800 !important;
  font-size: 20px !important;
  margin-right: 12px !important;
  line-height: 40px;
}

.header .header_link:hover {
  font-weight: 500 !important;
  color: var(--main-color) !important;
}

.header .header_home {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.header .header-left {
  margin-right: auto;
}

.header .header-right {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px;
}

.header .header-right > a .MuiSvgIcon-root {
  font-size: 16px !important;
  margin-right: 12px;
  color: black;
}
