:root {
  --main-color: #6c63ff;

  --main-title-color: black;

  --main-text-color: grey;

  --main-link-color: lightsalmon;

  --colorBlack: black;

  --font-google-sans-bold: "Google Sans Bold";

  --font-google-sans-medium: "Google Sans Medium";

  --font-google-sans-regular: "Google Sans Regular";

  /* Max width */

  --titleSize: 75px;

  --subtitleSize: 40px;

  --textSize: 20px;

  /* Max width - 1380 */

  --titleSizeMaxWidth1380: 65px;

  --subtitleSizeMaxWidth1380: 35px;

  --textSizeMaxWidth1380: 18px;

  /* Max width - 1110 */

  --titleSizeMaxWidth1100: 50px;

  --subtitleSizeSizeMaxWidth1100: 30px;

  --textSizeMaxWidth1100: 17px;

  /* Max width - 760  */

  --titleSizeMaxWidth760: 50px;

  --subtitleSizeSizeMaxWidth760: 30px;

  --textSizeMaxWidth760: 15px;
}

.top-60 {
  margin-top: 60px;
}

.top-20 {
  margin-top: 20px;
}

@media (max-width: 991px) {
  .top-60 {
    margin-top: 80px;
  }
}

/* .container_shadow {
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.1);
} */

.main_border {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-style: solid;
  border-width: 1px;
}

.main_content {
  min-height: 100vh;
  margin-bottom: 30px;
  padding: 30px;
}

.section_title {
  position: relative;
  display: inline-block;
  padding: 0 3px;
}
