.dev-icons {
    padding-left: 0;
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
}

.problem-links-inline {
    display: inline-block;
    margin-right: 5px;
}